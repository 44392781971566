import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/usecases/hero"
import Cases from "components/sections/usecases/cases"
import Trial from "components/common/trial"
import { seoTitleSuffix } from "config"

const AboutPage = ({ data }) => {
  const { frontmatter } = data.usecases.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
     <Layout headerContent={data.header.edges} footerContent={data.footer.edges} lang="fr">
        <SEO
          frontmatter={frontmatter}
          lang="fr"
        />
        <Hero content={data.hero.edges} />
        <Cases content={data.cases.edges} />
        <div className="gradient">
        </div>
        
      </Layout>
    </GlobalStateProvider>
  )
}


export default AboutPage

export const pageQuery = graphql`
  {
    usecases: allMdx(filter: { fileAbsolutePath: { regex: "/usecases/fr/usecases/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/usecases/fr/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            buttonText
            buttonLink
          }
        }
      }
    }
    cases: allMdx(filter: { fileAbsolutePath: { regex: "/usecases/fr/cases/" } }) {
      edges {
        node {
          exports {
            items {
              company
              title
              subtitle
              tags
              link
              image {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
