import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ContentWrapper from "styles/contentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding: 0 0 3rem 0;
  padding-bottom: 6rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    margin-top: 3rem;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5rem;
    @media(min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1.5rem;
    }
  }
`

const StyledFilters = styled.div`
  background-color: rgba(48, 191, 217, .1);
  .filters {
    max-width: ${({ theme }) => theme.pageWidth};
    margin: 0 auto;
    padding: 1rem 15px;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 1rem 2.5rem;
    }
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-auto-rows: minmax(3rem,auto);
    grid-gap: 2rem;
    align-items: center;
    .form-group {
      .label {
        line-height: 1.3rem;
        font-size: .9rem;
        text-align: left;
        letter-spacing: -.05rem;
        color: #00495d;
      }
      .form-control {
        display: block;
        width: 100%;
        height: calc(2.25rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
      }
    }
  }
`

const StyledItem = styled.div`
  box-shadow: 0 5px 29px 0 rgba(0, 0, 0, .08);
  border: 0;
  margin: 0 .5rem;
  .image {
    height: 12rem;
    width: 100%;
  }
  .body {
    padding: 2rem;
    border-top: .4rem solid #30bfd9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 12rem);
    h3 {
      margin-bottom: 0.5rem;
    }
    .title {
      margin-top: -.375rem;
      margin-bottom: .6rem;
      font-weight: 600;
    }
    .tags {
      margin: 1rem -0.3rem;
      display: flex;
      justify-content: flex-start;
      span {
        margin: 0 0.3rem;
        color: #89989b;
        text-decoration: none;
        background-color: rgba(48, 191, 217, .1);
        padding: .3rem .4rem .3rem .3225rem;
      }
    }
  }
`

const Cases = ({ content }) => {
  const { exports } = content[0].node
  const { items } = exports
  return (
    <StyledSection id="Cases">
      <StyledFilters>
        <div className="filters">
          <div className="form-group">
            <label className="label" for="company-type">Company type</label>
            <select className="form-control" id="company-type">
              <option value="all">all</option>
              <option value="software">Software</option>
              <option value="corporate">Corporate</option>
            </select>
          </div>
          <div className="form-group">
            <label className="label" for="team">Team</label>
            <select className="form-control" id="team">
              <option value="all">all</option>
              <option value="hr-recruiting">HR &amp; Recruiting</option>
              <option value="ia-it">IA &amp; IT</option>
              <option value="innovation-change">Innovation &amp; Change</option>
            </select>
          </div>
          <div className="form-group">
            <label className="label" for="use-case">Use case</label>
            <select className="form-control" id="use-case">
              <option value="all">all</option>
              <option value="inbound-recruiting">Inbound recruiting</option>
              <option value="candidate-data-infrastructure">Candidate Data Infrastructure</option>
            </select>
          </div>
        </div>
      </StyledFilters>
      <StyledContentWrapper>
        {items.map(({company, title, subtitle, tags, link, image}, key) => {
          const cardImage = getImage(image)
          return (
            <StyledItem key={key}>
                <GatsbyImage image={cardImage} className="image" alt={title} />
                <div className="body">
                  <div>
                    <h3>{company}</h3>
                    <div className="title">{title}</div>
                    <p>{subtitle}</p>
                  </div>
                  <div>
                    <div className="tags">
                      {tags.map(tag => <span>{tag}</span>)}
                    </div>
                    <a href={link} target="_blank" rel="nofollow noopener">Read more</a>
                  </div>
                </div>
            </StyledItem>
          )
        })}
      </StyledContentWrapper>
    </StyledSection>
  )
}

Cases.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Cases
