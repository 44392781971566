import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"

import Context from "context/"
import ContentWrapper from "styles/contentWrapper"
import SplashScreen from "components/splashScreen"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  padding: 3.5rem 0;
  background-image: url('https://hrflow-ai.imgix.net/gradient-bg.webp');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: center;
  height: 28rem;
  padding-top: 8rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .intro {
      text-align: center;
      z-index: 3;
      .title {
        margin-bottom: 1.5rem;
        color: white;
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          margin-bottom: 1rem;
        }
      }
      .subtitle {
        color: white;
        font-family: ${({ theme }) => theme.fonts.plainSkeleton};
        max-width: 50rem;
      }

      .button {
        text-transform: uppercase;
        background: #fff;
        line-height: normal;
        font-size: 0.8rem;
        text-align: center;
        letter-spacing: 0.065rem;
        text-transform: uppercase;
        color: #00495d;
        font-weight: 600;
        width: 11.3rem;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.2rem;
        margin: auto;
        margin-top: 2rem;
        &:hover {
          text-decoration: none!important;
          opacity: .9;
        }
      }
    }
  }
`

const Hero = ({ content }) => {
  const { frontmatter } = content[0].node
  const { isIntroDone, darkMode } = useContext(Context).state
  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, gControls])
  

  return (
    <StyledSection id="hero">
      {!isIntroDone && <SplashScreen />}
      <StyledContentWrapper>
        <div className="intro">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={gControls}
            data-testid="animated-heading"
          >
            <h1 className="title">
              {frontmatter.title}
            </h1>
            <h4 className="subtitle">{frontmatter.subtitle}</h4>
            <Link to={frontmatter.buttonLink} className="button">{frontmatter.buttonText}</Link>
          </motion.div>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
